.about-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    background-color: #ebebeb;
    position: relative;
    padding-top: 120px;
    padding-bottom: 100px;
    width: 100%;
}

.about-title {
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
}

.about {
    font-family: 'Inter';
    font-size: 35pt;
    margin-bottom: 10px;
    color: #444649;
}

.about-underline {
    width: 100px;
    height: 4px;
    background-color: #444649;
    margin: 0 auto;
    margin-bottom: 30px;
}

.about-content {
    display: flex;
}

.bio {
    font-family: 'Inter';
    margin: 20px;
    font-size: 16pt;
    display: flex;
    flex-direction: column;
    width: 60%;
    justify-content: center;
    text-align: justify;
    padding: 0;
}

.bio p {
    margin: 7px;
}

.bio-color {
    font-weight: bold;
    color: #00b5c2;
}

.skills {
    font-family: 'Inter';
    font-size: 13pt;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 40%;
    align-items: center;
    row-gap: 0;
    margin: 20px;
    height: 200px;
}

.skill {
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin: 5px;
    background-color: rgb(153,153,153,.2);
    color: #666;
    height: 30px;
    border-radius: 10px;
    transition: color 0.3s, background-color 0.3s, transform 0.2s, box-shadow 0.2s;
}

.skill:hover {
    color: white;
    background-color: #00b5c2; /* #e31b6d */
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.skill p {
    margin: 5px;
    line-height: 0,2;
}

.reachout {
    font-weight: bold;
    text-decoration: underline;
    color: #00b5c2;
    cursor: pointer;
}

.resume {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14pt;
    padding: 5px 15px;
    margin: 10px;
    margin-top: 30px;
    background-color: rgb(153,153,153,.2);
    color: #666;
    height: 30px;
    border-radius: 10px;
    transition: color 0.3s, background-color 0.3s, transform 0.2s, box-shadow 0.2s;
    border: none;
    cursor: pointer;
    max-width: 210px;
}

.resume:hover {
    color: white;
    background-color: #00b5c2;
    transform: scale(1.05);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

/* Small screens */
@media (max-width: 640px) {
    .about-container {
        width: 100%;
        overflow-x: hidden;
    }

    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bio {
        width: 95%;
        padding: 20px;
        text-align: justify;
        font-size: 14pt;
        margin: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .skills {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
        justify-content: center;
        width: 95%;
        font-size: 10pt;
        margin-bottom: 30px;
    }

    .skill {
        padding: 5px 10px;
        box-sizing: border-box;
    }

    .resume {
        font-size: 10pt;
        width: 177px;
    }
}

/* Medium screens */
@media (min-width: 641px) and (max-width: 1007px) {
    .about-container {
        width: 100%;
        overflow-x: hidden;
    }

    .about-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .bio {
        width: 95%;
        padding: 20px;
        text-align: justify;
        font-size: 14pt;
        margin: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .skills {
        width: 95%;
        font-size: 14pt;
    }

    .resume {
        font-size: 12pt;
        width: 199.9px;
    }
}

/* Large screens */
@media (min-width: 1008px) {
    .resume {
        font-size: 12pt;
        width: 199.9px;
    }
}