.projects-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    background-color: #d2d2d2;
    position: relative;
    padding-top: 120px;
    padding-bottom: 150px;
    width: 100%;
}

.project-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: scroll;
}

.project-title {
    font-family: 'Inter';
    font-size: 35pt;
    margin-bottom: 10px;
    color: #444649;
}

.project-underline {
    width: 140px;
    height: 4px;
    background-color: #444649;
    margin-bottom: 30px;
}

.projects {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 80px;
    text-decoration: none;
    margin-bottom: 100px;
}

.project {
    margin: 10px;
    width: 600px;
    height: 300px;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    text-align: center;
    position: relative;
    transition: transform 0.2s, box-shadow 0.2s, opacity 0.2s;
    align-items: flex-end;
    border: 1.5px solid #e31b6d;
}

.project:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 1;
}

.project-name {
    margin-top: 50px;
    margin-bottom: 0;
}

.project-text {
    width: 100%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    font-size: 30px;
    color: black;
    justify-content: flex-end;
    background-color: #F8F8F8;
    opacity: 0;
    transition: opacity 0.3s ease;
    font-family: 'Inter';
}

.project-text:hover {
    opacity: 0.9;
}

.project-type {
    margin-bottom: 50px;
    margin-top: 5px;
    font-size: 15pt;
    color: #e31b6d;
}

.learn-more {
    width: 150px;
    height: 40px;
    font-size: 15pt;
    color: black;
    background-color: transparent;
    margin: 0 auto;
    border: solid 2px #e31b6d;
    transition: color 0.3s ease;
    transition: background-color 0.3s ease;
}

.learn-more:hover {
    background-color: #e31b6d;
    color: white;
    cursor: pointer;
}

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    opacity: 0;
    transition: opacity 0.3s ease;
}

.modal.open {
    display: flex;
    opacity: 1;
}

.modal-content {
    background-color: white;
    width: 90%; /* Adjust width */
    max-width: 600px; /* max width */
    max-height: 100vh; /* Height in viewport units */
    padding: 20px;
    text-align: left;
    border-radius: 5px;
    /* justify-content: left; */
    overflow: hidden; /* Prevent content from overflowing */
}

.modal-content > modal-images > img {
    width: 100%;
    height: auto;
}

.modal-images {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height as needed */
}

.project-image {
    width: 100%; /* Make all images the same width */
    height: auto; /* Maintain the aspect ratio */
    max-width: 100%; /* Ensure images don't exceed their natural size */
}

.modal-text {
    position: relative;
}

.modal-project-name {
    font-size: 20pt;
    margin-bottom: 0;
}

.prev-button,
.next-button {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    z-index: 1;
}

.prev-button {
    left: 0;
}

.next-button {
    right: 0;
}

.github {
    color: #333;
    cursor: pointer;
    font-size: 10pt;
}

.modal-project-description {
    font-size: 12pt;
    margin-top: 10px;
}

.close-modal {
    width: 90px;
    height: 90px;
    color: #333;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.view-site > form > button {
    height: 45px;
    font-size: 12pt;
    margin-top: 10px;
    padding: 8px 16px;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer;
    border: solid 2px #e31b6d;
    transition: color 0.3s ease;
    transition: background-color 0.3s ease;
}

.view-site > form> button:hover {
    background-color: #e31b6d;
    color: white;
    cursor: pointer;
}

/* Small screens */
@media (max-width: 640px) {
    .projects-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    .projects {
        margin-bottom: 30px;
    }

    .project {
        margin-top: 1px;
        margin-bottom: 1px;
        width: 356px;
        height: 300px;
    }

    .project-image {
        width: 100%;
        height: auto;
    }

    .modal-project-description {
        font-size: 12pt;
    }

    .modal-content {
        width: 100%;
    }

    .modal-project-name {
        font-size: 15pt;
    }

    .view-site > form > button {
        height: 35px;
        font-size: 10pt;
        margin-top: 10px;
        padding: 0 5px;
    }
    
    .view-site > form> button:hover {
        background-color: #e31b6d;
        color: white;
        cursor: pointer;
    }
}

/* Medium screens */
@media (min-width: 641px) and (max-width: 1007px) {
    .projects-container {
        width: 100%;
    }

    .modal-project-name {
        font-size: 19pt;
    }

    .modal-project-description {
        font-size: 13pt;
    }
}