.canvas-container {
    position: relative;
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    z-index: 0;
    cursor: pointer;
}

.text-content {
    font-family: 'Urbanist';
    color: white;
    font-size: 45pt;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 1;
    white-space: nowrap;
}

.text-content p {
    margin: 0 0;
}

.red-text {
    color: #e31b6d;
}

#tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
}

@media (max-width: 640px) {
    .canvas-container {
        width: 100%;
    }

    .text-content {
        font-size: 20pt;
        /* display: -webkit-box; */
        /* -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
    }
}

@media (min-width: 641px) and (max-width: 1007px) {
    .canvas-container {
        width: 100%;
    }

    .text-content {
        font-size: 25pt;
        /* display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden; */
    }
}

@media (min-width: 1008px) {
    .canvas-container {
        width: 100%;
    }

    .text-content {
        font-size: 32pt;
    }
}